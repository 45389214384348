import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static classes = ['toggle'];
  static targets = ['sentinel', 'class'];
  static values = {
    threshold: { type: Number, default: 1 },
    rootMargin: { type: String, default: '0px 0px 0px 0px' }
  };

  connect() {
    if (this.hasSentinelTarget) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.classTarget.classList.remove(...this.toggleClasses);
          } else {
            this.classTarget.classList.add(...this.toggleClasses);
          }
        });
      });

      this.observer.observe(this.sentinelTarget);
    } else {
      useIntersection(this, {
        threshold: this.thresholdValue,
        rootMargin: this.rootMarginValue
      });
    }

    this.checkInitialState();
  }

  checkInitialState() {
    if (this.hasSentinelTarget) return;

    if (this.isVisible()) {
      this.appear({ target: this.element });
    } else {
      this.disappear({ target: this.element });
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  appear(entry) {
    entry.target.classList.remove(...this.toggleClasses);
  }

  disappear(entry) {
    entry.target.classList.add(...this.toggleClasses);
  }
}
